<template>
  <div>
    <el-card class="box-card" shadow="never">
      <div slot="header" class="clearfix">
        <h3>订单详情</h3>
      </div>
      <el-row>
        <el-col :xl="24" style="margin-bottom: 20px">
          <h4>团购信息</h4>
        </el-col>
        <el-col v-for="item in baseInfoConfig" class="pt5 pb5" :key="item.key" :xl="item.className ? 24 : 12">
          <span class="field-name" style="flex-basis: auto">{{ item.label }}</span>
          <span class="field-value">{{ item.render ? item.render(info) : info[item.key] }}</span>
        </el-col>
      </el-row>
    </el-card>
    <el-card shadow="never" style="border-top: none">
      <div slot="header" class="clearfix">
        <h4>商品信息</h4>
        <div class="item mt10" v-for="item in consigneeConfig" :key="item.key">
          <span class="field-name">{{ item.label }}</span>
          <span class="field-value">{{ item.render ? item.render(info) : info[item.key] }}</span>
        </div>
      </div>
      <h4>参团信息</h4>
      <commonTable :tableData="info.ordersModelList" :loading="loading" @handleSizeChange="handleSizeChange"
        :currentPage="currentPage" :total="total">
        <template v-slot:table>
          <el-table-column prop="ordersNo" align="center" label="订单编号" width="200px" show-overflow-tooltip />
          <el-table-column prop="nickeName" align="center" label="用户昵称" show-overflow-tooltip />
          <el-table-column prop="payPrice" align="center" label="付款金额" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ (scope.row.payPrice / 100).toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column prop="createTime" align="center" label="下单时间" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ setGetDataTimeSec(scope.row.createTime) }}
            </template>
          </el-table-column>
          <el-table-column prop="assembleTime" align="center" label="拼团时间" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ setGetDataTimeSec(scope.row.assembleTime) }}
            </template>
          </el-table-column>
          <el-table-column prop="orderState" align="center" label="订单状态" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.orderState | isState(scope.row.orderState) }}
            </template>
          </el-table-column>
        </template>
      </commonTable>
    </el-card>
  </div>
</template>

<script>
import { getGoodsAssembleInfo } from "@/api/joinGroup";
import { getDataTimeSec } from "@/utils";
import commonTable from "@/components/common/commonTable";
export default {
  data() {
    return {
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
      info: {
        goodSpecModelList: [],
      },
      baseInfoConfig: [
        {
          label: "团购状态：",
          render(info) {
            const type = {
              0: "-",
              1: "拼团中",
              2: "拼团成功",
              3: "拼团失败",
              4: "已退款",
            };
            return type[info.state];
          },
        },
        { label: "单账号最多可开团数：", key: "openRegiment" },
        { label: "开团团长：", key: "nickeName" },
        { label: "单账号最多可参团数：", key: "joinRegiment" },
        { label: "成团人数：", key: "assembleNum" },
        { label: "单账号最多可购买数：", key: "maxPurchase" },
        {
          label: "还差人数：",
          className: "col-lg-12",
          render(info) {
            return info.assembleNum - info.putAssemble || 0;
          },
        },
        {
          label: "开始时间：",
          render(info) {
            return getDataTimeSec(info.createTime);
          },
          className: "col-lg-12",
        },
        {
          label: "结束时间：",
          render(info) {
            return getDataTimeSec(info.endTime);
          },
          className: "col-lg-12",
        },
      ],
      consigneeConfig: [
        {
          label: "商品名称：",
          render(info) {
            var specName = info.goodSpecModelList ? info.goodSpecModelList[0].specName : '';
            return `${info.goodsName}${specName}`;
          },
        },
      ],
    };
  },
  components: {
    commonTable,
  },
  computed: {
    setGetDataTimeSec() {
      return (val) => getDataTimeSec(val);
    },
  },
  filters: {
    isState(val) {
      const type = {
        1: "待付款",
        2: "待发货",
        3: "待收货",
        4: "已完成",
        5: "交易取消",
        6: "退款成功",
      };
      return type[val];
    },
  },
  created() {
    this.getGoodsAssembleInfo();
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
    },
    async getGoodsAssembleInfo() {
      const { id } = this.$route.query;
      try {
        const result = await getGoodsAssembleInfo(
          { assembleId: id },
          { showLoading: true }
        );
        this.info = result.data;
        this.total = result.data.ordersModelList.length
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>